<template>
  <div class="home">
    <div class="header">
      <span>证照信息</span>
    </div>
    <div class="nav">营业执照信息</div>
    <ul class="content" v-if="enterpriseInfo">
      <li>
        <span>企业名称</span>
        <div>{{enterpriseInfo.realName}}</div>
      </li>
      <li>
        <span>企业类型</span>
        <div>--</div>
      </li>
      <li>
        <span>证件类型</span>
        <div>营业执照</div>
      </li>
      <li>
        <span>所属地区</span>
        <div v-if="enterpriseBaseInfo">{{enterpriseBaseInfo.detailedAddress}}</div>
      </li>
      <li>
        <span>营业执照到日期</span>
        <div>{{enterpriseInfo.expirationDate}}</div>
      </li>
      <li>
        <span>国别</span>
        <div>中国</div>
      </li>
      <li>
        <span>企业信用代码</span>
        <div>{{enterpriseInfo.realCode}}</div>
      </li>
      <li>
        <span>注册地址</span>
        <div v-if="enterpriseBaseInfo">{{enterpriseBaseInfo.enterpriseAddress}}</div>
      </li>
      <li>
        <span>营业执照</span>
        <div><a v-if="enterpriseInfo.enterpriseLicense" @click="check(enterpriseInfo.enterpriseLicense,false)">查看</a></div>
      </li>
      <li>
        <span>企业现场照片</span>
        <div>
          <a v-if="enterpriseInfo.enterpriseSitePhotos1||enterpriseInfo.enterpriseSitePhotos2" @click="check(enterpriseInfo.enterpriseSitePhotos1,true)">查看</a>
        </div>
      </li>
      <li style="width:100%">
        <span>经营范围</span>
        <div>--</div>
      </li>
    </ul>
    <a-modal
      title="图片信息"
      :visible="visible"
      :footer="null"
      width="550px"
      @cancel="visible=false"
    >
      <img v-if="!isCarsour" :src="imageUrl" style="width:500px;height:500px;margin:0 auto;" alt="">
      <a-carousel arrows v-else>
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px;zIndex: 1"
          :dots='position'
        >
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" />
        </div>
        <div><img v-if="enterpriseInfo.enterpriseSitePhotos1" :src="enterpriseInfo.enterpriseSitePhotos1" style="width:500px;height:500px;margin:0 auto;" alt=""></div>
        <div><img v-if="enterpriseInfo.enterpriseSitePhotos2" :src="enterpriseInfo.enterpriseSitePhotos2" style="width:500px;height:500px;margin:0 auto;" alt=""></div>
      </a-carousel>
    </a-modal>
    <!-- <div class="nav">法人信息</div>
    <ul class="content">
      <li>
        <span>法人代表姓名</span>
        <div>--</div>
      </li>
      <li>
        <span>年龄</span>
        <div>--</div>
      </li>
      <li>
        <span>性别</span>
        <div>--</div>
      </li>
      <li>
        <span>职业</span>
        <div>--</div>
      </li>
      <li>
        <span>法人代表证件类型</span>
        <div>--</div>
      </li>
      <li>
        <span>法人代表证件号</span>
        <div>--</div>
      </li>
      <li>
        <span>证件有效期开始日</span>
        <div>--</div>
      </li>
      <li>
        <span>证件有效期到期日</span>
        <div>--</div>
      </li>
      <li style="width:100%">
        <span>工作地址</span>
        <div>--</div>
      </li>
    </ul> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { ACCESS_TOKEN,USER_INFO } from "@/store/mutation-types";
import {
  getCompanyIndentifyInfo,
  getLoginInfo,
  enterpriseBaseInfo
} from "@/api/api";
export default {
  name: 'Home',
  created () {
    this.getLoginInfo();
  },
  data () {
    return {
      enterpriseInfo: null,
      enterpriseBaseInfo: null,
      loginInfo: null,
      visible: false,
      imageUrl: '',
      isCarsour: false,
      userInfo:null,
      position:false
    }
  },
  methods: {
    // 查看图片
    check(url,bl) {
      this.imageUrl = url;
      this.isCarsour = bl;
      this.visible = true;
    },
    // 获取用户登录信息
    getLoginInfo () {
      getLoginInfo().then(res => {
        if (res.success) {
          this.loginInfo = res.result;
          Vue.ls.set(USER_INFO,res.result,7 * 24 * 60 * 60 * 1000)
          this.getCompanyIndentifyInfo()
        }
      })
    },
    // 获取营业执照信息
    getCompanyIndentifyInfo () {
      getCompanyIndentifyInfo(this.loginInfo.enterpriseId).then(res => {
        if (res.success) {
          this.enterpriseInfo = res.result
          if (this.enterpriseInfo) {
            this.enterpriseInfo.expirationDate = this.enterpriseInfo.expirationDate.split(' ')[0]
            this.userInfo = Vue.ls.get(USER_INFO);
            // 获取基本信息
            enterpriseBaseInfo(this.userInfo.enterpriseId).then(res => {
              if (res.success) {
                this.enterpriseBaseInfo = res.result
              }
            })
          }
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    color: #283039;
    font-size: 18px;
    font-weight: bold;
  }
}
.nav{
  color: #283039;
  font-size: 14px;
  margin: 30px 0 18px 0;
}
.content{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  li{
    display: flex;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    font-size: 12px;
    color: #727682;
    span{
      width: 140px;
      height: 54px;
      background: #FAFAFA;
      padding: 20px 18px;
    }
    div{
      padding: 20px 18px;
      height: 54px;
    }
  }
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 500px;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 1);
  border-radius: 50%;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots{
  display: none !important;
}
</style>
